<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="button-bar">
        <button type="button" class="btn btn-warning mr-2" (click)="onRefresh()">
            <i-feather name="refresh-cw" class="mr-2"></i-feather> Refresh
        </button>
        <button type="button" class="btn btn-success" (click)="onLocationAdd()">
            <i-feather name="plus"></i-feather> Add New Location
        </button>
    </div>
    <ngx-datatable class="bootstrap core-bootstrap" [rows]="rows" [columnMode]="ColumnMode.force" [headerHeight]="50"
        [footerHeight]="50" [limit]="5" rowWidth="auto" rowHeight="auto">

        <ngx-datatable-column name="Name" prop="name">
        </ngx-datatable-column>

        <ngx-datatable-column name="Location Type" prop="locationTypeName">
        </ngx-datatable-column>

        <ngx-datatable-column name="City" prop="city">
        </ngx-datatable-column>

        <ngx-datatable-column name="Pincode" prop="zipcode">
        </ngx-datatable-column>

        <ngx-datatable-column name="Actions">
            <ng-template ngx-datatable-cell-template let-row="row" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                <div class="custom-checkbox custom-control custom-control-inline">
                    <input type="checkbox" id="locationCheck-{{row.id}}"
                           [checked]="getChecked(row)"
                           class="custom-control-input"
                           (change)="onCheckboxChange($event, row)">
                    <label for="locationCheck-{{row.id}}" class="custom-control-label"> </label>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary mr-2" (click)="onCancel()">
        <i-feather name="Minus" class="mr-2"></i-feather>Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="onConfirm()">
        <i-feather name="check-square" class="mr-2"></i-feather>select Location
    </button>
</div>