<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <span [innerHTML]="body"> </span>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onOk()">
        <i-feather name="check-square" class="mr-2"></i-feather>Okay
    </button>
</div>
