<div [class]="'app-header header-shadow'">
    <div class="logo-src">
        <p>RK CATERERS</p>
    </div>
    <div class="app-header__content">
        <div class="app-header-left">
            <p> Welcome  <strong>{{loggedOnUserName}}</strong></p>
           
        </div>
        <div class="app-header-right">
            <app-notification-box></app-notification-box>

            <app-user-box></app-user-box>
        </div>
    </div>

    <div class="app-header__mobile-menu">
        <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
                [ngClass]="{'is-active' : globals.toggleSidebarMobile}" (click)="toggleSidebarMobile()">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </button>
    </div>

    <div class="app-header__menu">
        <span>
            <button class="btn-icon btn-icon-only btn btn-primary" [ngClass]="{'is-active' : globals.toggleHeaderMobile}"
                    (click)="toggleHeaderMobile()">
                <div class="btn-icon-wrapper">
                    <fa-icon [icon]="faEllipsisV"></fa-icon>
                </div>
            </button>
        </span>
    </div>
</div>
